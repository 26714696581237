import { routes } from 'utils/routes';
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile.svg';
import { Link } from 'react-router-dom';
import { Typography, useBackground } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared';

export const LoginAvatar = (): React.ReactElement => {
  const shouldHaveBg = useBackground();
  return (
    <Link
      to={routes.login}
      className="flex items-center mx-2 sm:mx-3 p-3 h-full sm:h-auto"
    >
      <ProfileIcon role="presentation" />
      <div className="hidden sm:block ml-2">
        <Typography
          size="paragraph"
          isBold
          color={
            shouldHaveBg
              ? sharedColors.neutral.white
              : sharedColors.neutral[900]
          }
        >
          <FormattedMessage
            defaultMessage="Log in"
            description="Navigation bar text prompting the user to log in"
          />
        </Typography>
      </div>
    </Link>
  );
};
