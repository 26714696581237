import { useParams } from 'react-router-dom';
import { PurchaseActivationFlow } from '@customer-frontend/page-templates';
import { logger } from 'utils/logging';
import { routes } from 'utils/routes';
import { useTheme } from '@chakra-ui/react';
import { BrandTheme } from '@eucalyptusvc/design-system';
import { MobileAppPrompt } from '../../../components/presentational/mobile-app-prompt';
import { ReactComponent as CircleTick } from '../../../assets/icons/circle-tick.svg';

const activationRoutes = {
  ...routes.consultation.activatePurchaseFlow,
  pharmacyInformation: routes.consultation.safetyInformation,
  practitionerLetter: routes.consultation.doctorsNote,
  profile: routes.profile,
  handlePurchaseOfferingsIntent: routes.handlePurchaseOfferingsIntent,
};

export default function ActivatePurchaseFlow() {
  const { consultationId } = useParams<{ consultationId: string }>();
  const theme = useTheme<BrandTheme>();

  const orderSummaryCardStyles = {
    headerSize: 'medium-paragraph',
    headerColor: 'text-secondary-brown-500',
    headerPadding: 'md:px-3 py-2',
    headerBgColor: 'bg-neutral-white',
    dueTodaySize: 'medium-paragraph',
    dueTodayColor: 'text-secondary-brown-500',
    dueTodayBgColor: 'bg-neutral-white',
    dueTodayBorderTColor: 'border-white',
    border: 'border-none',
    mobileBorder: 'border-t border-secondary-cream-400',
    mobilePadding: 'px-0',
    dividerBorderColor: 'border-secondary-cream-400',
    highlightsTextColor: 'text-secondary-brown-500',
  } as const;

  return (
    <PurchaseActivationFlow
      consultationId={consultationId}
      logger={logger}
      routes={activationRoutes}
      styles={{
        layout: {
          mainBgColorClassName: 'bg-secondary-cream-300',
          sideBgColorClassName: 'bg-secondary-cream-300',
          headerBorderClassName: 'border-secondary-cream-400',
          headerTextClassName: 'text-secondary-brown-500',
          orderSummary: {
            desktopHeaderSize: 'large-paragraph',
            card: orderSummaryCardStyles,
            addonDrawer: {
              productDescriptionTextColorClassName: 'text-secondary-brown-500',
            },
          },
        },
        reviewSection: {
          card: {
            textColorClassName: 'text-secondary-brown-500',
            accordionBorderColorClassName: 'border-secondary-cream-400',
            titleBgColor: 'bg-neutral-white',
            titleBorderBottomColor: 'border-secondary-cream-400',
            treatmentPlanTextColor: 'text-secondary-brown-300',
          },
          clinicalInfo: {
            buttonPalette: 'default',
            checkboxPalette: 'default',
            borderColorClassName: 'border-transparent',
          },
          faq: {
            accordionPalette: 'default',
          },
        },
        addDetailsSection: {
          emailHintTextContainer: 'text-secondary-brown-400',
          backBtnPalette: 'default',
        },
        confirmSection: {
          buttons: {
            confirm: 'alternate',
            back: 'default',
            linkButton: 'default',
          },
          buttonSubText: theme.colors.neutral['700'],
          updateDetailsBorderColor: 'border-secondary-brown-500',
          updateDetailsDivideColor: 'divide-secondary-cream-400',
          backgroundColor: 'bg-neutral-white',
          borderRadius: 'rounded-lg',
          border: 'border-none',
        },
        tierSelectionSection: {
          card: {
            textColorClassName: 'text-secondary-brown-500',
            highlightBgColorClassName: 'bg-primary-400',
            highlightRadioBgColor: 'bg-secondary-brown-500',
            highlightRadioBorderColor: 'border-secondary-brown-500',
            borderClassName: '',
            titleTextColor: 'text-secondary-brown-500',
            borderBottomColor: 'border-secondary-cream-400',
            giftBgColor: 'bg-neutral-white',
            giftBorderBottomColor: 'border-secondary-cream-400',
          },
          backButtonPalette: 'default',
          borderColorClassName: 'border-secondary-brown-500 border-opacity-20',
        },
        addonSection: {
          backButtonPalette: 'default',
          borderColorClassName: 'border-scondary-brown-100',
          bannerTextColorClassName: 'text-secondary-brown-500',
          productDescriptionTextColorClassName: 'text-secondary-brown-500',
          oneOffWarningTextColor: 'text-secondary-brown-500',
        },
        receiptSection: {
          receiptSectionBgColor: 'bg-secondary-cream-300',
          orderConfirmTitleColor: 'text-neutral-black',
          orderConfirmBgColor: 'bg-neutral-white',
          tickIconColor: 'text-neutral-white',
          tickIconBgColor: 'bg-green-700',
          card: {
            ...orderSummaryCardStyles,
            border: 'border border-secondary-brown-100',
            headerPadding: 'px-4 md:px-3 py-2',
            mobilePadding: 'px-4',
          },
          ratingTextColor: theme.colors.secondary.brown['500'],
          contactSupportTextColor: 'text-neutral-700',
        },
      }}
      thankYouIcon={<CircleTick />}
      downloadAppPrompt={
        <MobileAppPrompt
          appleClickElementName="purchaseActivationFlowAppleDownloadButton"
          googleClickElementName="purchaseActivationFlowGoogleDownloadButton"
        />
      }
    />
  );
}
