import { useResponsive } from '@eucalyptusvc/design-system';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UseBackgroundReturnValue } from './types';

export function useBackground(): UseBackgroundReturnValue {
  const [shouldHaveBgImage, setShouldHaveBgImage] = useState(false);
  const location = useLocation();
  const { isDesktop } = useResponsive();

  useEffect(() => {
    const checkBackgroundImage = () => {
      setShouldHaveBgImage(
        (location.pathname === '/login' || location.pathname === '/signup') &&
          isDesktop,
      );
    };

    checkBackgroundImage();
    window.addEventListener('resize', checkBackgroundImage);

    return () => {
      window.removeEventListener('resize', checkBackgroundImage);
    };
  }, [isDesktop, location]);

  return shouldHaveBgImage;
}
